import React from 'react'
import { Checkbox } from 'antd'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import styles from './Filters.module.scss'

const Favourites: React.FC = () => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const showFavourites = router.query.favourites === 'true'

  const handleFavourites = () => {
    const query = { ...router.query, favourites: !showFavourites }
    router.push({ query }, undefined, { scroll: false, shallow: true, locale: i18n.language })
  }

  return (
    <Checkbox
      checked={showFavourites}
      onClick={handleFavourites}
    >
      <span className={styles['titleFiters']}>
        &nbsp;{t('filters.favourites')}
      </span>
    </Checkbox>
  )
}

export default Favourites
