import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography
} from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './RequestForProposalModal.module.scss'
const { Title, Text } = Typography
import { useTranslation } from 'next-i18next'
import {
  ProjectOption,
  ProjectOptionNameType,
  RequestForProposalPayload
} from 'src/interfaces/InterfaceGlobal'
import { getProjectOptionsList } from 'src/features/projects/projectsAPI'
import {
  fetchCountries,
  sendRequestForProposal
} from 'src/features/users/usersAPI'
import { loadNamesCountries } from 'src/helpers/translate'
import { useAppSelector } from 'src/app/hooks'
import RequestProposalResults from './RequestProposalResults/RequestProposalResults'

interface Props {
    open: boolean
    onClose?: Function
}

const RequestForProposalModal: React.FC<Props> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation()
  const [projectTypeSelected, setProjectTypeSelected] = useState(null)
  const [projectOptions, setProjectOptions] = useState<ProjectOption[]>([])
  const [countries, setCountries] = useState([])
  const [sent, setSent] = useState(false)
  const [show, setShow] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  const [form] = Form.useForm()

  const currencies = useAppSelector((state) => state.compensation.currencies)

  const filteredOptions = (
    projectOptions: ProjectOption[],
    type: ProjectOptionNameType
  ) => {
    return projectOptions.filter((po) => po.name === type)
  }

  const fieldState = !projectTypeSelected
    ? 'COMPENSATION'
    : projectTypeSelected === 'compensation'
      ? 'COMPENSATION'
      : 'ENERGY'

  const fields = {
    COMPENSATION: {
      standardList: filteredOptions(projectOptions, 'standard'),
      typeList: filteredOptions(projectOptions, 'type'),
      measure: 'tCO₂'
    },
    ENERGY: {
      standardList: filteredOptions(
        projectOptions,
        'standard_energy_type'
      ),
      typeList: filteredOptions(projectOptions, 'energy_source_type'),
      measure: 'MWh'
    }
  }[fieldState]

  const handleCancel = () => {
    setSent(false)
    setShow(false)
    onClose && onClose()
  }

  useEffect(() => {
    getProjectOptionsList().then((res) => setProjectOptions(res))
    fetchCountries().then(async (res) => {
      const language = i18n.language || 'en'
      const translatedCountries = await loadNamesCountries(res, language)

      const sortedCountries = translatedCountries.toSorted((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      )
      setCountries(sortedCountries)
    })
    setShow(open)
  }, [open])

  const handleProjectTypeChange = (value: string) => {
    setProjectTypeSelected(value)
    if (value) {
      form.resetFields(['standards', 'types'])
    }
  }

  const handleSend = (values) => {
    setLoadingRequest(true)

    sendRequestForProposal(values)
      .then(() => {
        setSent(true)

        setLoadingRequest(false)
      })
      .catch((err) => {
        console.error(err)
        setSent(true)

        setLoadingRequest(false)
      })
  }

  const handleBack = () => {
    setSent(false)
  }

  return (
    <Modal
      visible={show}
      title={
        !sent && (
          <Title
            level={5}
            type={null}
            className={styles['modal-title']}
          >
            {t('RequestProposal.ModalTitle')}
          </Title>
        )
      }
      onCancel={handleCancel}
      width={650}
      centered={true}
      footer={null}
    >
      {!sent ? (
        <Form
          onFinish={(values: RequestForProposalPayload) => {
            handleSend(values)
          }}
        >
          <div className='fade-animation'>
            <Title level={5} type='secondary'>
              {t('RequestProposal.BigLabel')}
            </Title>
            <br />
            <Title level={5} type={null}>
              {t('RequestProposal.SubLabel')}
            </Title>
            <Divider />

            {/* Project Type Select */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name='project_type'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    optionFilterProp='children'
                    size='large'
                    className='select-input'
                    onChange={handleProjectTypeChange}
                    filterOption={null}
                    placeholder={t(
                      'RequestProposal.ProjectTypePlaceholder'
                    )}
                  >
                    <Select.Option value={'compensation'}>
                      {t(
                        'RequestProposal.Kind.Compensation'
                      )}
                    </Select.Option>
                    <Select.Option value={'energy'}>
                      {t('RequestProposal.Kind.Energy')}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Text
                  type={'secondary'}
                  className={styles['text-margin']}
                >
                  {t('RequestProposal.ProjectTypelabel')}
                </Text>
              </Col>
            </Row>
            {/**************************************/}

            {/* Standard Select */}
            <Row>
              <Col span={24}>
                <br />
                <Form.Item
                  name='standards'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    optionFilterProp='children'
                    size='large'
                    className='select-input'
                    filterOption={null}
                    mode='multiple'
                    listHeight={300}
                    tokenSeparators={[' ', ',']}
                    placeholder={t(
                      'RequestProposal.StandardsPlaceholder'
                    )}
                  >
                    {fields.standardList.map(
                      (standard, idx) => (
                        <Select.Option
                          key={idx}
                          value={standard.value}
                        >
                          {standard.value}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Text
                  type={'secondary'}
                  className={styles['text-margin']}
                >
                  {t('RequestProposal.StandardLabel')}
                </Text>
              </Col>
            </Row>
            {/**************************************/}

            {/* Country Select */}
            <Row>
              <Col span={24}>
                <br />
                <Form.Item
                  name='countries'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    optionFilterProp='children'
                    size='large'
                    className='select-input'
                    filterOption={null}
                    mode='multiple'
                    listHeight={300}
                    tokenSeparators={[' ', ',']}
                    placeholder={t(
                      'RequestProposal.CountriesPlaceholder'
                    )}
                  >
                    {countries.map((country) => (
                      <Select.Option
                        key={country.id}
                        value={country.id}
                      >
                        <img
                          src={country.flag}
                          alt={`flag-${country?.name}`}
                          className={styles['image']}
                        />
                        <span
                          className={styles['text']}
                        >
                          {country?.name}
                        </span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Text
                  type={'secondary'}
                  className={styles['text-margin']}
                >
                  {t('RequestProposal.CountryLabel')}
                </Text>
              </Col>
            </Row>
            {/**************************************/}

            {/* Type Select */}
            <Row>
              <Col span={24}>
                <br />
                <Form.Item
                  name='types'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    optionFilterProp='children'
                    size='large'
                    className='select-input'
                    filterOption={null}
                    mode='multiple'
                    listHeight={300}
                    tokenSeparators={[' ', ',']}
                    placeholder={t(
                      'RequestProposal.TypesPlaceholder'
                    )}
                  >
                    {fields.typeList.map((type, idx) => (
                      <Select.Option
                        key={idx}
                        value={type.value}
                      >
                        {type.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Text
                  type={'secondary'}
                  className={styles['text-margin']}
                >
                  {t('RequestProposal.TypesLabel')}
                </Text>
              </Col>

              <br />
            </Row>
            {/**************************************/}

            {/* Stock Select */}
            <Row>
              <Col xs={24} lg={12} className='mt-4'>
                <Text type={'secondary'}>
                  {t('RequestProposal.StockLabel')}
                </Text>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name='stock'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: t(
                        'RequestProposal.Error.OnlyIntegers'
                      )
                    }
                  ]}
                >
                  <InputNumber
                    type={'number'}
                    placeholder={t(
                      'RequestProposal.StockPlaceHolder'
                    )}
                    min={2000}
                    max={Number.MAX_SAFE_INTEGER}
                    className={
                      styles['custom-input-number']
                    }
                    size='large'
                    addonAfter={
                      <Text type={'secondary'}>
                        {fields.measure}
                      </Text>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            {/**************************************/}

            {/* Price Select */}
            <Row>
              <Col xs={24} lg={12} className='mt-4'>
                <Text type={'secondary'}>
                  {t('RequestProposal.Measure', {
                    measure: fields.measure
                  })}
                </Text>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name='price'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    },
                    {
                      pattern: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/,
                      message: t(
                        'RequestProposal.Error.OnlyTreeDecimalsError'
                      )
                    }
                  ]}
                >
                  <InputNumber
                    type={'number'}
                    placeholder={t(
                      'RequestProposal.PricePlaceHolder'
                    )}
                    className={
                      styles['custom-input-number']
                    }
                    size='large'
                  />
                </Form.Item>
              </Col>
            </Row>
            {/**************************************/}

            {/* Currency Select */}
            <Row>
              <Col xs={24} lg={12} className='mt-4'>
                <Text type={'secondary'}>
                  {t('RequestProposal.CurrencyLabel')}
                </Text>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name='currency'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'RequestProposal.Error.RequiredValidation'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    optionFilterProp='children'
                    size='large'
                    className='select-input'
                    filterOption={null}
                    placeholder={t(
                      'RequestProposal.CurrencyPlaceHolder'
                    )}
                  >
                    {currencies.map((c, idx) => (
                      <Select.Option
                        key={idx}
                        value={c.id}
                      >
                        {c.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/**************************************/}

            <Button
              block
              size='large'
              type='primary'
              htmlType='submit'
              className='radius-100 mt-2'
              loading={loadingRequest}
            >
              {loadingRequest
                ? t('RequestProposal.Sending')
                : t('RequestProposal.Send')}
            </Button>
          </div>
        </Form>
      ) : (
        <RequestProposalResults setIsResultsVisible={handleBack} />
      )}
    </Modal>
  )
}

export default RequestForProposalModal
