import React, { useEffect } from 'react'

// Syles
import styles from './Registry.module.scss'
// Hooks
import { useDispatch } from 'react-redux'
// Components
import { Checkbox } from 'antd'
// Redux
import {
  setRefreshFilters,
  setRegistrySelected,
} from 'src/features/filters/filtersSlice'
// Hooks
import { useAppSelector } from 'src/app/hooks'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { useRouter } from 'next/router'
import { queryFilterWriter } from 'src/helpers/utils'

const Registry: React.FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const registryTypes = useAppSelector(
    (state) => state.compensation.registryTypes
  )
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const registrySelected = useAppSelector(
    (state) => state.filters.registry
  )

  const onChangeRegistry = (standard: any) => {
    if(!registrySelected){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_REGISTRY'))
    }
    const isIn = registrySelected?.find((el) => el === standard)
    if (isIn) {
      const newFilter = registrySelected.filter((el) => el !== standard)
      dispatch(setRegistrySelected(newFilter))
    } else {
      dispatch(setRegistrySelected([...registrySelected, standard]))
    }
    dispatch(setFiltersKeys(['registry']))
    dispatch(setRefreshFilters(!refreshFilters))
  }
  useEffect(() => {
    queryFilterWriter('registry',
      registrySelected,
      null,
      null,
      null,
      router
    )
  }, [registrySelected])
  return (
    <div data-testid='registry-filter' className={styles['registry-filter']}>
      {registryTypes?.map((registry, index) => {
        return (
          <Checkbox
            className={
              registrySelected.includes(registry)
                ? styles['registry-text-selected']
                : styles['registry-text']
            }
            checked={registrySelected.includes(registry)}
            onClick={() => onChangeRegistry(registry)}
            key={index}
          >
            {registry}
          </Checkbox>
        )
      })}
    </div>
  )
}

export default Registry
