/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
// Styles
import styles from './Filters.module.scss'
// Components
import { Button, Checkbox, Collapse, Divider } from 'antd'
import Price from './FiltersTypes/Price/Price'
const { Panel } = Collapse
import SDGs from './FiltersTypes/SDGs/SDGs'
import ProjectKind from './FiltersTypes/ProjectKind/ProjectKind'
import Year from './FiltersTypes/Year/Year'
// Hooks
import { useTranslation } from 'next-i18next'
import ProjectOptions from './FiltersTypes/ProjectsOptions/ProjectsOptions'
import { useAppSelector } from 'src/app/hooks'
import SearchByName from './FiltersTypes/SearchByName/SearchByName'
import StandardType from './FiltersTypes/StandardType/StandardType'
import Characteristics from './FiltersTypes/Characteristics/Characteristics'
import {
  setFilters,
  setInitialState,
  setIsReset,
  setPrice,
  setPriceChange,
  setRefreshFilters,
  setSearch,
  setStock,
  setStockChange,
  setYearChange,
  setYearSince,
  setYearUntil,
  setSessionLoaded
} from 'src/features/filters/filtersSlice'
import { useDispatch } from 'react-redux'
import {
  getExpensivePrice,
  getHigherStock,
  getNewestVintage,
  getOlderVintage
} from 'src/helpers/utils'
import {
  setMaxPrice,
  setMaxStock
} from 'src/features/compensation/compensationSlice'
import { useRouter } from 'next/router'
import Stock from './FiltersTypes/Stock/Stock'
import Impacts from './FiltersTypes/Impact/Impact'
import { SaveFilters } from 'src/interfaces/InterfaceGlobal'
import {
  setFiltersKeys,
  setLoadingSliderPrice,
  setLoadingSliderStock
} from 'src/features/ui/uiSlice'
import Mechanism from './FiltersTypes/Mechanism/Mechanism'
import Continent from './FiltersTypes/Continent/Continent'
import CategoryType from './FiltersTypes/CategoryType/CategoryType'
import Registry from './FiltersTypes/Registry/Registry'
import { UpOutlined } from '@ant-design/icons'
import MinimunPurchase from './FiltersTypes/MinimunPurchase/MinimumPurchase'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { resetSuggesterState } from 'src/features/search-suggester'
import Favourites from './Favourites'

interface Props {
  changeSort?: Function
}

const FilterBadge = ({ items }: { items?: any[] }) => {
  if (items?.length <= 0) return null

  return (
    <div className={styles['badge']}>{items.length}</div>
  )
}

const Filters: React.FC<Props> = ({ changeSort }) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const filters = useAppSelector((state) => state.filters)
  const kindSelected = useAppSelector((state) => state.filters.projectKind)
  const projects = useAppSelector((state) => state.project.project)
  const filtersKeys = useAppSelector((state) => state.ui.filtersKeys)
  const countries = useAppSelector((state) => state.filters.countries)
  const name = useAppSelector((state) => state.filters.name)
  const registry = useAppSelector((state) => state.filters.registry)
  const yearChange = useAppSelector((state) => state.filters.yearChange)
  const priceChange = useAppSelector((state) => state.filters.priceChange)
  const stockChange = useAppSelector((state) => state.filters.stockChange)
  const stock = useAppSelector((state) => state.filters.withoutStock)
  const mechanism = useAppSelector((state) => state.compensation.mechanism)
  const standards = useAppSelector(
    (state) => state.filters.selectedStandardType
  )
  const SDGselected = useAppSelector((state) => state.filters.SDGs)
  const tagsSelected = useAppSelector(
    (state) => state.filters.selectedProjectTags
  )
  const mechanismSelected = useAppSelector(
    (state) => state.filters.selectedMechanism
  )
  const typeSelected = useAppSelector((state) => state.filters.selectedTypes)
  const impactsSelected = useAppSelector(
    (state) => state.filters.selectedImpacts
  )
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const filtersLoaded = useAppSelector((state) => state.filters.sessionLoaded)
  const impacts = useAppSelector((state) => state.compensation.impact)
  const loadingSliderPrice = useAppSelector(
    (state) => state.ui.loadingSliderPrice
  )
  const loadingSliderStock = useAppSelector(
    (state) => state.ui.loadingSliderStock
  )

  const resetFilters = () => {
    dispatch(checkCookies())
    dispatch(
      trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_RESET_FILTERS')
    )
    sessionStorage.setItem('filters', null)
    dispatch(setInitialState())
    dispatch(setLoadingSliderPrice(!loadingSliderPrice))
    dispatch(setLoadingSliderStock(!loadingSliderStock))
    dispatch(setYearSince(getOlderVintage(projects?.results)))
    const currentYear = new Date().getFullYear()
    const newestVintageYear = getNewestVintage(projects?.results)
    dispatch(setYearUntil(currentYear > newestVintageYear ? currentYear : newestVintageYear))
    dispatch(setYearChange(false))
    dispatch(setPriceChange(false))
    dispatch(setStockChange(false))
    dispatch(setMaxStock(getHigherStock(projects?.results)))
    dispatch(setStock([0, getHigherStock(projects?.results)]))
    dispatch(setMaxPrice(getExpensivePrice(projects?.results).price))
    dispatch(setPrice([0.01, getExpensivePrice(projects?.results).price]))
    dispatch(setSearch(''))
    dispatch(setIsReset(true))
    router.push(`/`, undefined, {
      locale: i18n.language, scroll: false
    })
    changeSort('-scoring')
    dispatch(setRefreshFilters(!refreshFilters))
    dispatch(resetSuggesterState())
  }


  const getMechanismOption = (saveMechanism:string[]) => {
    const avoidance = [
      'Avoidance',
      'Eliminación',
      'Élimination',
      '제거',
      'Evitação',
      'Entfernung',
      'Evitamento'
    ]
    const removal = [
      'Removal',
      'Evitación',
      'Évitement',
      '피하기',
      'Remoção',
      'Vermeidung',
      'Rimozione'
    ]

    if (saveMechanism?.length === mechanism.length) {
      return mechanism
    }
    const isSameCategory = (current: string, saved: string) => {
      const bothAreAvoidance = avoidance.includes(current) && avoidance.includes(saved)
      const bothAreRemoval = removal.includes(current) && removal.includes(saved)

      return bothAreAvoidance || bothAreRemoval
    }

    const newArrayMechanism = mechanism.filter(m => saveMechanism?.some(sm => isSameCategory(m, sm)))
    return newArrayMechanism
  }

  useEffect(() => {
    const history = sessionStorage.getItem('history')
      ? JSON.parse(sessionStorage.getItem('history'))
      : []

    if (history.includes(router.asPath)) {
      if (
        sessionStorage.getItem('filters') &&
        sessionStorage.getItem('filters') != 'null' &&
        !filtersLoaded
      ) {
        const saveFilters: SaveFilters = JSON.parse(
          sessionStorage.getItem('filters')
        )

        // traducir la opcion guardada al idioma actual
        saveFilters.selectedMechanism = getMechanismOption(
          saveFilters.selectedMechanism
        )
        dispatch(setFilters(saveFilters))
        dispatch(setFiltersKeys(filtersKeys))
        dispatch(setRefreshFilters(!refreshFilters))
      } else {
        const saveFilters: SaveFilters = {
          name: filters.name,
          projectKind: filters.projectKind,
          yearSince: filters.yearSince,
          yearUntil: filters.yearUntil,
          price: filters.price,
          selectedStandardType: filters.selectedStandardType,
          selectedEnergyType: filters.selectedEnergyType,
          selectedCompensationType: filters.selectedCompensationType,
          selectedMechanism: filters.selectedMechanism,
          withoutStock: filters.withoutStock,
          order: filters.order,
          stock: filters.stock,
          stockChange: filters.stockChange,
          priceChange: filters.priceChange,
          yearChange: filters.yearChange,
          selectedImpacts: filters.selectedImpacts,
          filtersKeys: filtersKeys,
          registry: filters.registry
        }

        sessionStorage.setItem('filters', JSON.stringify(saveFilters))
      }
    } else {
      sessionStorage.setItem('filters', null)
      sessionStorage.setItem('history', JSON.stringify([]))
      dispatch(setSessionLoaded(true))
    }
  }, [filters])
  return (
    <div data-testid='filters-container'>
      <div>
        &nbsp;&nbsp;
        <Favourites />
        <Collapse
          className={styles['filters-container']}
          bordered={false}
          expandIconPosition='right'
          expandIcon={({ isActive }) => (
            <UpOutlined
              rotate={isActive ? 0 : 180}
              style={{ color: '#777E8B' }}
            />
          )}
          ghost={true}
          defaultActiveKey={filtersKeys?.length > 0 ? [...filtersKeys, 'projectKind'] : ['projectKind']}
        >
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.kind')}
                </div>
                <FilterBadge items={kindSelected} />
              </div>
            }
            className={styles['filter-text']}
            key='projectKind'
          >
            <ProjectKind />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel header={
            <div className={styles['container-category']}>
              <div className={styles['titleFiters']}>
                {t('filters.country')}
              </div>
              <FilterBadge items={countries} />
            </div>
          }
          className={styles['filter-text-double']}
          key='country'
          >
            <Continent />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel header={
            <div className={styles['container-category']}>
              <div className={styles['titleFiters']}>
                {t('filters.year')}
              </div>
              {yearChange && (
                <div className={styles['badge']}>1</div>
              )}
            </div>
          }
          className={styles['filter-text']}
          key='year'
          >
            <Year />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.price')}
                </div>
                {priceChange && (
                  <div className={styles['badge']}>1</div>
                )}
              </div>
            }
            className={styles['filter-text']}
            key='price'
          >
            <Price />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.stock')}
                </div>
                {stockChange || !stock ?
                  <div className={styles['badge']}>1</div>
                  : ''
                }
              </div>
            }
            className={styles['filter-text']}
            key='stock'
          >
            <ProjectOptions />
            <MinimunPurchase />
            <Stock />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.search-by-name')}
                </div>
                {name && (
                  <div className={styles['badge']}>1</div>
                )}
              </div>
            }
            className={styles['filter-text']}
            key='name'
          >
            <SearchByName />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.standard')}
                </div>
                {standards?.length > 0 && (
                  <div className={styles['badge']}>
                    {standards?.length}
                  </div>
                )}
              </div>
            }
            className={styles['filter-text']}
            key='standardType'
          >
            <StandardType />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.registry')}
                </div>
                <FilterBadge items={registry} />
              </div>
            }
            className={styles['filter-text']}
            key='registry'
          >
            <Registry />
          </Panel>
          <Divider className={styles['divider']} />
          {mechanism?.length > 0 &&
            <Panel header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.mechanism')}
                </div>
                <FilterBadge items={mechanismSelected} />
              </div>
            }
            className={styles['filter-text']}
            key='mechanism'
            >
              <Mechanism />
            </Panel>
          }
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.sdg')}
                </div>
                <FilterBadge items={SDGselected} />
              </div>
            }
            className={styles['filter-text']}
            key='SDG'
          >
            <SDGs />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel
            header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.characteristics')}
                </div>
                <FilterBadge items={tagsSelected} />
              </div>
            }
            className={styles['filter-text']}
            key='characteristics'
          >
            <Characteristics />
          </Panel>
          <Divider className={styles['divider']} />
          <Panel header={
            <div className={styles['container-category']}>
              <div className={styles['titleFiters']}>
                {t('filters.compensation-type')}
              </div>
              {typeSelected?.length > 0 &&
                  <div className={styles['badge']}>
                    {typeSelected.length}
                  </div>
              }
            </div>
          }
          className={styles['filter-text']}
          key='projectType'
          >
            <CategoryType />
          </Panel>
          <Divider className={styles['divider']} />
          {impacts &&
            <Panel header={
              <div className={styles['container-category']}>
                <div className={styles['titleFiters']}>
                  {t('filters.impact')}
                </div>
                <FilterBadge items={impactsSelected} />
              </div>
            }
            className={styles['filter-text']}
            key='impact'
            >
              <Impacts />
            </Panel>
          }
        </Collapse>
        <Button className={styles['reset-btn']} onClick={resetFilters}>
          {t('filters.reset')}
        </Button>
      </div>
    </div>
  )
}

export default Filters
