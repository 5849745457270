import { Col } from 'antd'
import React from 'react'

interface EmptySpacesProps {
  count: number
}

const EmptySpaces: React.FC<EmptySpacesProps> = ({ count }) => {
  const spaces = Array.from({ length: count }, (_, i) => (
    <Col key={i}>
      <div style={{ width: '288px', height: '312px', borderRadius: '8px', background: '#F5F5F5' }} />
    </Col>
  ))

  return <>{spaces}</>
}

export default EmptySpaces
