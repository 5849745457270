import { AppDispatch, AppState } from 'src/app/store'
import { getCountries, getSDGs } from 'src/features/projects/projectsAPI'
import { capitalizeFirstLetter, queryFilterReader } from 'src/helpers/utils'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { setFilteredProjects, setQueryFilters, setQueryWrite } from 'src/features/filters/filtersSlice'

export const handleQueryFilters = (query: any) => async (dispatch: AppDispatch, getState: () => AppState) => {
  const { compensation: {
    projectTags,
    mechanism: mechanisms,
    differentCategories: listOfCategories,
    standardTypes
  },
  filters: {
    queryWrite,
  } } = getState()
  if (queryWrite) {
    dispatch(applyFilters())
    dispatch(setQueryWrite(false))
    return
  }

  const newQueryFilters = {
    search: query.search ? query.search[0] : '',
    countries: [],
    selectedProjectTags: [],
    selectedTypes: [],
    SDGs: [],
    projectKind: [],
    selectedMechanism: [],
    selectedStandardType: []
  }

  // query param for category
  if (query.category) {
    const projectKindQuery = Array.isArray(query.category)
      ? query.category.map(kind => capitalizeFirstLetter(kind.toLowerCase()))
      : [capitalizeFirstLetter(query.category.toLowerCase())]
    newQueryFilters.projectKind = projectKindQuery
  }

  if (query.characteristic) {
    newQueryFilters.selectedProjectTags = queryFilterReader(
      'characteristic',
      projectTags,
      'name',
      'name_en',
      'id'
    )
    dispatch(setFiltersKeys(['characteristics']))
  }


  if (query.country) {
    try {
      const res = await getCountries()
      newQueryFilters.countries = queryFilterReader(
        'country',
        res,
        'code2',
        null,
        null
      )
      dispatch(setFiltersKeys(['country']))
    } catch (e) {
      console.error(e)
    }
  }
  if (query.standard) {
    try {
      newQueryFilters.selectedStandardType = standardTypes.filter(st=>query.standard.includes(st))
    } catch (e) {
      console.error(e)
    }
  }

  // query param for types
  if (query.type) {
    let typesList = []
    listOfCategories.forEach((categories) => {
      typesList = typesList.concat(categories.options)
    })
    newQueryFilters.selectedTypes = queryFilterReader(
      'type',
      typesList,
      'value',
      null,
      null
    )
    dispatch(setFiltersKeys(['projectType']))
  }

  // query param for mechanisms
  if (query.mechanism) {
    const avoidance = [
      'Avoidance',
      'Emisones Evitadas',
      'Évitement',
      '기피',
      'Evitação',
      'Vermeidung',
      'Evitare'
    ]
    const removal = [
      'Removal',
      'Remoción',
      'Suppression',
      '제거',
      'Remoção',
      'Entfernung',
      'Rimozione'
    ]
    const qMechanisms = [query.mechanism].flat()
    newQueryFilters.selectedMechanism = mechanisms.filter((m) =>
      qMechanisms.find((qM) =>
        [avoidance.includes(m) ? avoidance : removal]
          .flat()
          .includes(qM)
      )
    )
    dispatch(setFiltersKeys(['mechanism']))
  }

  // query param for SDG
  if (query.sdg) {
    try {
      const res = await getSDGs()
      newQueryFilters.SDGs = queryFilterReader(
        'sdg',
        res.results,
        'name',
        null,
        'id'
      )

      dispatch(setFiltersKeys(['SDG']))
    } catch (e) {
      console.error(e)
    }
  }
  dispatch(setQueryFilters(newQueryFilters))
}

const includesSDG = (data: string, project: any) =>
  !!project.sdg_compliances.find(el => el.name.toLowerCase().includes(data))

const includesDescription = (data: string, project: any) =>
  project.descriptions.some(el => el.text.toLowerCase().includes(data))

const includesTags = (data: string, project: any) =>
  project.tags.some(el => el?.name?.toLowerCase().includes(data))

const includesRegistry = (data: string, project: any) => {
  let include = false
  project.tags.forEach((el) => {
    if (el?.name?.toLowerCase().includes(data)) {
      include = true
    }
  })
  return include
}

const compareStringsInsensitive = (str1?: string, str2?: string): boolean => {
  return !str1 || !str2 ? false :
    str1.localeCompare(str2, undefined, { sensitivity: 'base' }) === 0
}
export const getFinalPrice = (project) => project.is_vintage ?
  project.vintage_items[0]?.final_unit_price || project.final_unit_price :
  project.final_unit_price

export const getPriceWithFee = (project) => {
  const finalPrice = getFinalPrice(project)
  return !finalPrice ? project.price : finalPrice.sell_base_amount + finalPrice.fee_amount
}
export const orderArray = (newArray, order) => {
  const sortedArray = JSON.parse(JSON.stringify(newArray || []))

  if (order === 'name') {
    return sortedArray.sort((a, b) => a.name?.localeCompare(b.name))
  }
  if (order === '-name') {
    return sortedArray.sort((a, b) => b.name?.localeCompare(a.name))
  }
  if (order === '-scoring') {
    return sortedArray.sort((a, b) => b.scoring - a.scoring)
  }
  if (order === 'price') {
    return sortedArray.sort((a, b) => getPriceWithFee(a) - getPriceWithFee(b))
  }
  if (order === '-price') {
    return sortedArray.sort((a, b) => getPriceWithFee(b) - getPriceWithFee(a))
  }

  return sortedArray
}
export const applyFilters = (orderedArray?: Array<any>) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    const { filters: { search,
      name,
      projectKind: kindFilter,
      registry,
      selectedTypes,
      selectedEnergyType,
      selectedCompensationType,
      yearSince,
      yearUntil,
      price,
      stock,
      withoutStock,
      minimumPurchase,
      selectedStandardType,
      selectedProjectTags,
      selectedMechanism: mechanismSelected,
      countries,
      SDGs,
      order,
      selectedImpacts
    },
    project: { project: projects } } = getState()
    let newArray = orderedArray || projects?.results
    const searchProperties = ['name', 'registry_depositing_name','sub_type', 'standard_energy_type', 'type', 'standard', 'kind', 'description','unit_of_measurement']
    if (search) {
      newArray = newArray?.filter((project) => {
        if (
          searchProperties.some(k=>
            project[k]?.toLowerCase()
              .includes(search.toLowerCase())
          ) ||
          project.country?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          includesSDG(search.toLowerCase(), project) ||
          includesDescription(search.toLowerCase(), project) ||
          includesTags(search.toLowerCase(), project) ||
          includesRegistry(search.toLowerCase(), project)
        ) {
          return project
        }
      })
    }

    if (registry?.length > 0) {
      newArray = newArray?.filter(el =>
        registry.some(reg => compareStringsInsensitive(el.registry_depositing_name, reg))
      )
    }

    if (name) {
      newArray = newArray?.filter((project) => {
        if (project.name.toLowerCase().includes(name.toLowerCase())) {
          return project
        }
      })
    }

    // Filter by project kind
    if (kindFilter?.length > 0) {
      newArray = newArray?.filter(el =>
        kindFilter.some(kind => compareStringsInsensitive(el.kind, kind))
      )
    }

    // filter by year
    if (yearSince && yearUntil) {
      const date = new Date().getFullYear()

      const newArrayYear = newArray?.filter((project) => {
        if (project.is_vintage && project.vintage_items.length == 0 && withoutStock) {
          return project
        }
        if (project.is_vintage) {
          return project.vintage_items.some(v=>v.year >= yearSince && v.year <= yearUntil)
        }
        return date >= yearSince && date <= yearUntil

      })
      newArray = newArrayYear.slice()
    }
    // filter by price
    if (price[0] && price[1]) {
      newArray = newArray?.filter((project) => {
        if (project.is_vintage && project.vintage_items.length == 0 && withoutStock) {
          return project
        }
        return ((getPriceWithFee(project)).toFixed(2) >= price[0] &&
          (getPriceWithFee(project)).toFixed(2) <= price[1]
        )

      })
    }
    // filter by stock
    if (stock?.[0] !== null && stock?.[1]) {
      newArray = newArray?.filter((project) => {
        if (project.is_vintage && project.vintage_items.length == 0 && withoutStock) {
          return project
        }
        if (project.is_free_donation) {
          return project
        }
        let minPurchase = project.kgco2_minimum_purchase < 1000 ? 1 : project.kgco2_minimum_purchase / 1000
        if (project.is_vintage) {
          let stockBalance = 0
          project.vintage_items.forEach(
            (vint) => (stockBalance += vint.stock_balance)
          )
          return (stockBalance >= stock[0] && minPurchase <= stock[1])
        }
        return (project.available_volume_credits >= stock[0] && minPurchase <= stock[1])
      })
    }


    // filter by minimun purchase
    if (!minimumPurchase) {
      newArray = newArray?.filter(
        (project) => project.kgco2_minimum_purchase <= 1
      )
    }

    // filter by energy type
    if (kindFilter?.includes('energy') && selectedEnergyType.length > 0) {
      let newArrayEnergy = []
      for (const eType of selectedEnergyType) {
        let filterArrayEnergy = newArray?.filter((el) => el.energy_type == eType
        )
        newArrayEnergy = [...newArrayEnergy, ...filterArrayEnergy]
      }
      newArray = newArrayEnergy
    }

    // filter by compensation type
    if (
      kindFilter?.includes('compensation') &&
      selectedCompensationType.length > 0
    ) {
      let newArrayCompensation = []
      for (const compType of selectedCompensationType) {
        let filterArrayEnergy = newArray?.filter((el) => el.type == compType
        )
        newArrayCompensation = [
          ...newArrayCompensation,
          ...filterArrayEnergy
        ]
      }
      newArray = newArrayCompensation
    }

    // filter by type
    if (selectedTypes?.length > 0) {
      let newArrayTypes = []
      for (const selType of selectedTypes) {
        let filterArrayTypes = newArray?.filter((el) =>
          el.type == selType.value ||
            el.energy_type == selType.value
        )
        newArrayTypes = [...newArrayTypes, ...filterArrayTypes]
      }
      newArray = newArrayTypes
    }

    // filter by standard type
    if (selectedStandardType?.length > 0) {
      let newArrayStandards = []
      for (const selStandard of selectedStandardType) {
        let filterArrayStandard = newArray?.filter((el) => el.standard == selStandard
        )
        newArrayStandards = [
          ...newArrayStandards,
          ...filterArrayStandard
        ]
      }
      newArray = newArrayStandards
    }

    // filter by project tags
    if (selectedProjectTags?.length > 0) {
      let newArrayTags = []
      let filterArrayTags = []
      for (const tagId of selectedProjectTags) {
        filterArrayTags = newArray?.filter((el) => {
          const existTag = el.tags?.find((tag) =>tagId == tag.id)
          return (existTag && !newArrayTags.includes(el))
        })
        newArrayTags = [...newArrayTags, ...filterArrayTags]
      }
      newArray = newArrayTags
    }

    // filter by country
    if (countries.length > 0) {
      let newArrayCountry = []
      for (const element of countries) {
        let filterArrayCountry = newArray?.filter((el) => el.country?.id == element.id
        )
        newArrayCountry = [...newArrayCountry, ...filterArrayCountry]
      }
      newArray = newArrayCountry
    }

    // filter by mechanism
    if (mechanismSelected?.length > 0) {
      let newArrayMechanism = []
      for (const element of mechanismSelected) {
        let filterArrayMechanism = newArray?.filter((el) => el.mechanism == element )
        newArrayMechanism = newArrayMechanism.concat(filterArrayMechanism)
      }
      newArray = newArrayMechanism
    }
    // filter by impact
    if (selectedImpacts?.length > 0) {
      let newArrayImpacts = newArray.filter(p=>p.impacts.find((i) => selectedImpacts.includes(i.name)))
      const removeDuplicatesArray = newArrayImpacts.filter(
        (item, index) => newArrayImpacts.indexOf(item) === index
      )
      newArray = removeDuplicatesArray
    }
    // filter by SDG
    if (SDGs.length > 0) {
      let newArraySDG = []
      for (const sdgR of SDGs) {
        newArray.forEach((project) => {
          project.sdg_compliances.forEach((sdg) => {
            if (sdg.id === sdgR) {
              newArraySDG.push(project)
            }
          })
        })
        newArray = newArraySDG.slice()
        newArraySDG = []
      }
    }
    // filter by show without stock
    if (!withoutStock) {
      newArray = newArray?.filter((p) => p.available_volume_credits > 0)
    }
    dispatch(setFilteredProjects(orderArray(newArray, order)))
  }
