import React, { useEffect } from 'react'

// Syles
import styles from './StandardType.module.scss'
// Hooks
import { useDispatch } from 'react-redux'
// Components
import { Checkbox } from 'antd'
// Redux
import {
  setRefreshFilters,
  setSelectedStandardType,
} from 'src/features/filters/filtersSlice'
// Hooks
import { useAppSelector } from 'src/app/hooks'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { queryFilterWriter } from 'src/helpers/utils'
import { useRouter } from 'next/router'

const StandardType: React.FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const standardTypes = useAppSelector(
    (state) => state.compensation.standardTypes
  )
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const standardSelected = useAppSelector(
    (state) => state.filters.selectedStandardType
  )

  const onChangeStandard = (standard: any) => {
    if(!standardSelected){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_STANDARD'))
    }
    const isIn = standardSelected?.find((el) => el === standard)
    if (isIn) {
      const newFilter = standardSelected.filter((el) => el !== standard)
      dispatch(setSelectedStandardType(newFilter))
    } else {
      dispatch(setSelectedStandardType([...standardSelected, standard]))
    }
    dispatch(setFiltersKeys(['standardType']))
    dispatch(setRefreshFilters(!refreshFilters))
  }
  useEffect(() => {
    queryFilterWriter('standard',
      standardSelected,
      null,
      null,
      null,
      router
    )
  }, [standardSelected])
  return (
    <div data-testid='standard-filter' className={styles['standard-filter']}>
      {standardTypes?.map((standard, index) => {
        return (
          <Checkbox
            className={
              standardSelected.includes(standard)
                ? styles['standard-text-selected']
                : styles['standard-text']
            }
            checked={standardSelected.includes(standard)}
            onClick={() => onChangeStandard(standard)}
            key={index}
          >
            {standard}
          </Checkbox>
        )
      })}
    </div>
  )
}

export default StandardType
