import React, { useEffect } from 'react'

// Syles
import styles from './Impact.module.scss'
// Hooks
import { useDispatch } from 'react-redux'
// Components
import { Checkbox } from 'antd'
// Redux
import {
  setSelectedImpacts,
  setRefreshFilters,
} from 'src/features/filters/filtersSlice'
// Hooks
import { useAppSelector } from 'src/app/hooks'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { useRouter } from 'next/router'
import { queryFilterWriter } from 'src/helpers/utils'

const Impacts: React.FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const selectedImpacts = useAppSelector(
    (state) => state.filters.selectedImpacts
  )
  const impacts = useAppSelector((state) => state.compensation.impact)

  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  const onChangeImpact = (impact: any) => {
    if(!selectedImpacts){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_IMPACTS'))
    }
    const isIn = selectedImpacts?.find((el) => el === impact)
    if (isIn) {
      const newFilter = selectedImpacts.filter((el) => el !== impact)
      dispatch(setSelectedImpacts(newFilter))
    } else {
      dispatch(setSelectedImpacts([...selectedImpacts, impact]))
    }
    dispatch(setFiltersKeys(['impact']))
    dispatch(setRefreshFilters(!refreshFilters))
  }
  useEffect(() => {
    queryFilterWriter('impact',
      selectedImpacts,
      null,
      null,
      null,
      router
    )
  }, [selectedImpacts])
  return (
    <div data-testid='impact-filter' className={styles['impact-filter']}>
      {impacts.map((impact, index) => {
        return (
          <>
            {impacts.length > 0 ? (
              <Checkbox
                className={
                  selectedImpacts.includes(impact)
                    ? styles['impact-text-selected']
                    : styles['impact-text']
                }
                checked={selectedImpacts.includes(impact)}
                onClick={() => onChangeImpact(impact)}
                key={'impact-'+index}
              >
                {impact}
              </Checkbox>
            ) : (
              ''
            )}
          </>
        )
      })}
    </div>
  )
}

export default Impacts
