import { AppDispatch } from 'src/app/store'
import { setProjectTags } from './compensationSlice'
import { getProjectsTags } from './compensationAPI'

export const fetchProjectTags = () => async (dispatch: AppDispatch) => {
  try {
    const projectTags = await getProjectsTags()
    dispatch(setProjectTags(projectTags))
  } catch (error) {
    console.error(error)
  }
}
