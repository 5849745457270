import React from 'react'
import { Col } from 'antd'
import CardPortfolio from 'src/components/CardProjects/CardPortfolio'
import CardDidYouKnowThat from 'src/components/CardDidYouKnowThat/CardDidYouKnowThat'
import CardProjects from 'src/components/CardProjects/CardProjects'
import styles from 'src/assets/styles/04_page/index.module.scss'
import { useAppSelector } from 'src/app/hooks'

interface CardRendererProps {
  item: any
  index: number
  inMobile: boolean
}

const CardRenderer: React.FC<CardRendererProps> = ({ item, index, inMobile }) => {
  const isPortfolio = (p) => Object.hasOwn(p, 'commercial_name')
  const {
    currencies,
  } = useAppSelector((state) => state.compensation)
  if (isPortfolio(item)) {
    return <CardPortfolio key={item.slug} portfolio={item} />
  }

  if (typeof item === 'number') {
    return (
      <Col className={styles['fade-in']} hidden={inMobile} key={index}>
        <CardDidYouKnowThat cardNumber={item} />
      </Col>
    )
  }

  return (
    <Col key={index}>
      <CardProjects project={item} currencyList={currencies} />
    </Col>
  )
}

export default CardRenderer
