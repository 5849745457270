import React, { useEffect, useState } from 'react'
import { getPopularFilters } from 'src/features/projects/projectsAPI'
import styles from './PopularFilters.module.scss'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Collapse } from 'antd'
import { UpOutlined } from '@ant-design/icons'


const { Panel } = Collapse

const PopularFilters = ({ queryFilters, mobile }) => {

  const router = useRouter()
  const { t } = useTranslation()
  const [popularFilters, setPopularFilters] = useState([])
  const [querySelected, setQuery] = useState('')
  const [labelSelected, setLabelSelected] = useState('')


  useEffect(() => {
    getPopularFilters()
      .then(setPopularFilters)

  }, [])
  const handleClick = async (query) => {

    setQuery(query)

    await router.replace({ pathname: router.pathname, query: query }, undefined, { scroll: false, shallow: true })
  }
  const queryString = new URLSearchParams(Object(router.query))?.toString()
  useEffect(() => {
    if (queryString === querySelected) {

      queryFilters()
    }
  }, [queryString])

  const PanelStyles = labelSelected ? styles['titleFilters-selection'] : styles['titleFilters']

  return (
    <div className={styles['popular-filters-container']}>

      {mobile ? <Collapse
        expandIconPosition='right'
        expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} style={{ color: '#777E8B' }}/>}
        ghost={false}
        className={styles['popular-filters-container-mobile']}
      >
        <Panel
          header={
            <div>
              <div className={PanelStyles}>
                {t('popularFilters')}
              </div>

              <div>
                {labelSelected}
              </div>

            </div>
          }

          className={styles['filter-text']}
          key='popularFilters'
        >
          {popularFilters.map(pf => (
            <div className={styles['popular-filter']}
              key={pf.position}
              onClick={() => {
                handleClick(pf.query_params)
                setLabelSelected(pf.label)
              }}>
              <div className={queryString === pf.query_params ? styles['popular-text-filter-selected'] : styles['popular-text-filter']}>  {pf.label}
              </div>
            </div>)
          )}

        </Panel>
      </Collapse>
        :
        <>
          <div className='popular-filters-label'>
            {t('popularFilters')}
          </div>
          <div className='popular-filters'>
            {popularFilters.map(pf => (
              <div className={'popular-filter' + (queryString === pf.query_params ? ' selected' : '')}
                key={pf.position + '-popular-filter'} onClick={() => {
                  handleClick(pf.query_params)
                  setLabelSelected(pf.label)
                }}>
                <div className='filter-label'>  {pf.label}
                </div>
                <div className='shadow' />
                <div className='shadow two' />
              </div>)
            )}
          </div>
        </>
      }
    </div>
  )
}

export default PopularFilters
