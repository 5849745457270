import { message } from 'antd'
import { Row, Col } from 'antd'
import Image from 'next/image'
import styles from 'src/assets/styles/04_page/index.module.scss'
import plant from 'src/assets/img/plant.png'

export const openNotification = (description: string, notificationTitle: string) => {
  message.success({
    content: (
      <Row>
        <Col span={1} />
        <Col span={20}><p className={styles['description']}>{description}</p></Col>
      </Row>
    ),
    className: styles['notification'],
    duration: 6,
    icon: (
      <Row>
        <Col span={1}><Image src={plant} alt='Plant' /></Col>
        <Col span={20}><h5 className={styles['notificationTitle']}>{notificationTitle}</h5></Col>
        <Col span={1} />
      </Row>
    )
  })
}
